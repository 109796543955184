import React from 'react'

export const Header = () => {


    const listaOptions = [
        {path:'home',name:'Home'},
        {path:'about',name:'About'},
        {path:'service',name:'Service'},
        {path:'blog',name:'Blog'},
        {path:'portafolio',name:'Portafolio'},
    ];

    const activeMenu = () =>{
        console.log('active');
        
    }

    return (
    <div>
        <div className="options-menu float-right lg:hidden ">
            <ul className="flex items-center relative top-1 ">
            {
                listaOptions.map((data)=>{
                    console.log(data.name)
                    return(
                        <li className="group mx-3 transition duration-200 ease-in transform hover:-translate-y-1 hover:scale-100">
                            <a className="transition-all duration-300 font-bold cursor-pointer py-2 px-1 transition duration-200 text-black group-hover:text-menu text-24 xl:text-20  pb-2 mb-7">
                                {data.name}
                            </a>
                            <hr className="relative top-2 rounded-sm w-14 xl:w-12 m-auto border-transparent border-t-4 transition duration-200 ease-in group-hover:border-primary group-hover:border-t-4 "/>
                        </li>
                    )
                })
            }
            
            <li className="mx-3">
                <a className="transition-all duration-300 font-semibold justify-center items-center cursor-pointer py-2 px-6 transition duration-200 text-white bg-contact rounded-full rounded-tr-none shadow-contact text-22 xl:text-18"> 
                Contact
                </a>
                {/* <hr className="w-8 m-auto border-transparent border-t-2 transition duration-200 ease-in group-hover:border-primary group-hover:border-t-2"/> */}
            </li>
            </ul>
        </div>
        <div className="float-right cursor-pointer hidden lg:block">
            <button onClick={activeMenu}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" 
                className="text-black" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                </svg>
            </button>
            <div className="menu-responsive w-249 fixed px-5 border-20 bg-white shadow-button right-[45px] z-10">
            {/* home about service blog portafolio */}
                <ul className="items-center relative top-1  text-center py-4" >
                    {
                        listaOptions.map((data)=>{
                            console.log(data.name)
                            return(
                                <li className="group mx-3 transition duration-200 ease-in transform hover:-translate-y-1 hover:scale-100 h-[43px]">
                                    <a className="transition-all duration-300 font-bold cursor-pointer py-2 px-1 transition duration-200 text-black 
                                    group-hover:text-menu
                                        group-
                                    text-24 xl:text-20  pb-2 mb-7">
                                        {data.name}
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div> 
        </div>
    )
}
