import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Header } from './components/Header';

function App() {


  const service1 = require('./assets/img/services/001-data.png').default;
  const service3 = require('./assets/img/services/002-phone.png').default;
  const service2 = require('./assets/img/services/003-paint.png').default;
  const service4 = require('./assets/img/services/004-app.png').default;
  const service5 = require('./assets/img/services/005-repair.png').default;
  const service6 = require('./assets/img/services/006-domain-registration.png').default;
  const portfolio1 = require('./assets/img/portafolio/homologa.png').default;

  return (
    <div className="px-10 sm:px-5 mx-auto pt-7 max-w-fullWidth">
      <div className="home-section bg-home-color bg-home-section bg-contain">
        <div className="header-section flex items-center">
          <div className="logoName flex w-1/2 sm:w-full">
            <div className="rounded-full bg-primary h-60 w-60 sm:w-45 sm:h-45 flex items-center justify-center mr-1">
              <p className="text-white size text-36 font-bold sm:text-26">R</p>
            </div>
            <div className="flex items-center ">
              <p className="text-29 sm:text-24 font-bold">Randy Huerta</p>
            </div>
          </div>
          <div className="menu-header w-1/2">
            <Header />
          </div>

        </div>

        <div className="hero-section flex w-full">
          <div className="text-hero w-1/2 pt-249 lg:pt-[80px] sm:pt-100 sm:w-full">
            <p className="transition-all duration-300 ease-in-out text-43 text-title font-bold lg:text-29">I´m Professional</p>
            <div className="rol-hero lg:relative lg:relative">
              <p className="transition-all duration-300 ease-in-out text-118 lg:text-[60px] font-bold leading-105 lg:leading-65">FrontEnd</p>
              <p className="transition-all duration-300 ease-in-out text-118 lg:text-[60px] font-bold leading-105 lg:leading-65">Developer</p>
            </div>
            <div className="button-hero flex mt-85 lg:mt-45">
              <a className="flex font-semibold justify-center  items-center cursor-pointer w-240 h-75 lg:w-150 lg:h-52 lg:text-20
                             transition duration-200 text-white text-22 bg-contact rounded-full shadow-contact text-center">Hire Me</a>
              {/* <button className=" rounded-full w-80 h-80  bg-primary ml-14 lg:ml-9">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" 
                className="m-auto text-white" viewBox="0 0 16 16">
                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                </svg>
              </button> */}
 
            </div>
          </div>
          <div className="image-hero w-1/2 pt-114 h-850">
            <div className="image-hero-ot bg-heroe-section w-full bg-contain h-full bg-top bg-no-repeat bg-origin-padding bg-opacity-25">
            </div>
          </div>
        </div>
      </div>
      <div className="section-service mt-78 mb-113 pt-20 pb-20">
        <div className=" w-full">
          <h4 className="text-center text-40 font-bold sm:text-22">Brindamos el Mejor Servicio de Desarrollo Web</h4>
          <p className="text-center text-15 font-semibold m-auto w-483 sm:w-full sm:text-15">Seguimos un flujo de trabajo profesional para brindarle el mejor servicio para sus paginas web.</p>
        </div>
        <div className="mt-88">
          <div className="grid grid-cols-3 lg:grid-cols-1">
            <div className="service-1 justify-center items-center text-center m-auto h-402 sm:h-300 w-full lg:max-w-[400px] pt-10 rounded-20 transition hover:shadow-services">
              <div className="h-115.34 mb-58 lg:mb-15"><img src={service1} className="m-auto" /></div>
              <h3 className="text-21 font-bold mb-25.1">Desarrollo de Apps Web</h3>
              <p className="text-15 font-semibold">Desarrollamos software a medida para tus negocios <br/> basados en tecnolog&iacute;s web</p>
            </div> 
            <div className="service-1 justify-center items-center text-center m-auto h-402 sm:h-300 w-full lg:max-w-[400px] pt-10 rounded-20 transition hover:shadow-services2">
            <div className="h-115.34  mb-58 lg:mb-15"><img src={service2} className="m-auto" /></div>
              <h3 className="text-21 font-bold mb-25.1">Diseño de Apps Web</h3>
              <p className="text-15 font-semibold">Dise&ntilde;amos tu pagina web para que tus <br/>productos/servicios lleguen a mas clientes.</p>
            </div>
            <div className="service-1 justify-center items-center text-center m-auto h-402 sm:h-300 w-full lg:max-w-[400px] pt-10 rounded-20 transition hover:shadow-services3">
            <div className="h-115.34  mb-58 lg:mb-15"><img src={service3} className="m-auto" /></div>
              <h3 className="text-21 font-bold mb-25.1">Diseño Web Responsive</h3>
              <p className="text-15 font-semibold">Nos encargamos de que tu pagina web se adapte <br/>a cualquier tipo de dispositivo</p>
            </div>
            {/* <div className="service-1 justify-center items-center text-center m-auto h-402">
            <div className="h-115.34  mb-58"><img src={service4} className="m-auto" /></div>
              <h3 className="text-21 font-bold mb-27.1">Website Ui</h3>
              <p className="text-15 font-semibold">Fast. Powerful & Most Secure Network<br/>
                 Solutions for Smart Home & Businesess
              </p>
            </div>
            <div className="service-1 justify-center items-center text-center m-auto h-402">
            <div className="h-115.34  mb-58"><img src={service5} className="m-auto" /></div>
              <h3 className="text-21 font-bold mb-27.1">Website Ui</h3>
              <p className="text-15 font-semibold">Fast. Powerful & Most Secure Network<br/>
                 Solutions for Smart Home & Businesess
              </p>
            </div> 
            <div className="service-1 justify-center items-center text-center m-auto h-402">
            <div className="h-115.34  mb-58"><img src={service6} className="m-auto" /></div>
              <h3 className="text-21 font-bold mb-27.1">Website Ui</h3>
              <p className="text-15 font-semibold">Fast. Powerful & Most Secure Network<br/>
                 Solutions for Smart Home & Businesess
              </p>
            </div> */}
          </div>
        </div>
        <div className="text-center w-full">
          {/* <button className="bg-primary w-176 h-63 text-white text-18 font-semibold rounded-full shadow-button">View All</button> */}
        </div>
      </div>
      {/* <div className="skills-section">
        <div>
          <img src="" alt="" />
        </div>
        <div>
          <h3>My Skills</h3>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
            </svg>
            <p>Photoshop</p>
          </div>
        </div>
      </div> */}
      <div className="section-portfolio pb-113 bg-section-portfolio pt-20 pb-20">
        <div className=" w-full mb-85">
          <h4 className="text-center text-40 font-bold sm:text-22">Mi trabajo reciente</h4>
          <p className="text-center text-15 font-semibold m-auto w-483 sm:w-full">Siempre mostramos el resultado de nuestro gran trabajo.</p>
        </div>
        <div className="web-portfolio">
          <div className=" grid grid-cols-3 lg:grid-cols-1 justify-items-center">
            <figure className="group w-383 h-255  flex overflow-hidden rounded-20  mb-50">
              <img src={portfolio1} className="
              transform scale-110 hover:transform hover:scale-125 transition duration-700 ease-in-out 
              " />
              <figcaption className="flex items-center bg-portfolio absolute w-383 h-255 text-center justify-center align-middle px-8 rounded-20 z-1  transition opacity-0 ease-in duration-500  group-hover:opacity-100">
                <div>
                  <h1 className="text-white  mb-7 text-24">High-end, custom residential renovaters serving Fraser Valley homeowners.</h1>
                  <button className="text-white font-medium text-18 rounded-20 border-portfolio bg-transparent border-2 px-5 py-2">Visit Website</button>
                </div>
              </figcaption>
            </figure>
            <figure className="group w-383 h-255  flex overflow-hidden rounded-20  mb-50">
              <img src={portfolio1} className="
              transform scale-110 hover:transform hover:scale-125 transition duration-700 ease-in-out 
              " />
              <figcaption className="flex items-center bg-portfolio absolute w-383 h-255 text-center justify-center align-middle px-8 rounded-20 z-1  transition opacity-0 ease-in duration-500  group-hover:opacity-100">
                <div>
                  <h1 className="text-white  mb-7 text-24">High-end, custom residential renovaters serving Fraser Valley homeowners.</h1>
                  <button className="text-white font-medium text-18 rounded-20 border-portfolio bg-transparent border-2 px-5 py-2">Visit Website</button>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div className="section-contact w-full px-14 pt-20 pb-20
      bg-gradient-to-r from-blue-800 to-blue-400">
        <form className="w-full max-w-fullWidth  w-[480px] mb-25 m-auto border-2 py-15 px-14 bg-white">
          <div className=" mt-5 mb-16 text-center">
              <h4 className="transition-all duration-300 ease-in-out text-40 text-black font-semibold">Conversemos</h4>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 ">
              <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                Nombre y Apellido
              </label>
              <input className="appearance-none block w-full border-black text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="" />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2">
                Correo electronico
              </label>
              <input className="appearance-none block w-full border-black  text-gray-700   rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="email" />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2">
                Mensaje
              </label>
              <textarea className=" no-resize appearance-none block w-full border-black text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-[120px] resize-none" id="message"></textarea>
            </div>
          </div>
          <div className="md:flex md:items-center">
            <div className="md:w-1/3 text-center my-15">
              <button className="w-[240px] shadow bg-contact-form  focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="button">
                Enviar
              </button>
            </div>
          </div>
        </form>
        <div className="mt-20 text-center w-full">
          <p className="transition-all duration-300 ease-in-out text-18 text-white font-semibold">Si deseas saber mas sobre mi, te comparto mis redes:</p>
          <div className="justify-items-center mt-3">
            <ul className="justify-items-center justify-center flex">
              <li className="mx-5">
                <a className="cursor-pointer" href="https://www.facebook.com/"  target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="text-white" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                  </svg>
                </a>
              </li>
              <li className="mx-5">
                <a className="cursor-pointer" href="https://twitter.com/home"  target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="text-white" viewBox="0 0 16 16">
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                  </svg>
                </a>
              </li>
              <li className="mx-5">
                <a className="cursor-pointer" href="https://www.linkedin.com/in/frank-randy-huerta-valdivia-10861182/" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="text-white" viewBox="0 0 16 16">
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
